@tailwind base;
@tailwind components;
@tailwind utilities;

/* TIPOGRAFIAS */
@import url('https://fonts.googleapis.com/css2?family=Archivo+Black&family=Montserrat:wght@400;500;700&display=swap');

*{
    font-family: 'Montserrat', sans-serif;
}

/* UNDERLINE LINKS NAV */
.nav_underline::after{
    content: '';
    width: 100%;
    display: block;
    height: 2px;
    color: white;
}

/* ESTILOS GENERALES PARA EL SITIO */
h1{
    font-family: 'Archivo Black', sans-serif;
    font-weight: bold;
    color: white;
}

h3{
    font-family: 'Archivo Black', sans-serif;
}

li{
    font-size: 16px;
    display: inline-block;
    /* color: white; */
}

a{
    font-family: 'Montserrat', sans-serif;
    color: white;
}

input:focus{
    outline: none;
}

/* OVERLAY */
.overlay{
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.678);
}

/* SECCIONES */
#hero{
    height: 83vh;
    background-size: cover;
    background-repeat: no-repeat;

}

.suscribete-title{
    color: white;
}